import React, { useEffect, useState } from 'react';

export const isUserAgentMobile = (): boolean => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile((navigator?.userAgent?.indexOf('Mobi') || -2) > -1);
  }, []);

  return isMobile;
};

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = React.useState(true);

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 768);

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
};

export const useIsTouch = () => {
  const [isTouch, setIsTouch] = React.useState(true);

  React.useEffect(() => {
    setIsTouch(window.innerWidth <= 1023);
    const handleResize = () => {
      setIsTouch(window.innerWidth <= 1023);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isTouch;
};

export const useIsDesktop = () => {
  const [isDesktop, setIsDesktop] = React.useState(true);

  React.useEffect(() => {
    setIsDesktop(window.innerWidth > 1023);

    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1023);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isDesktop;
};
