import React, { useEffect, useLayoutEffect, useState } from 'react';
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { DisplayFilter } from '../pages/work';
import { useProjectMoodboard } from '../hooks/useProjectMoodboard';
import { Language, MoodboardItem, Status } from '../mlva-types';

interface ProjectsMoodboardProps {
  previousDisplayFilter: DisplayFilter | undefined;
  displayFilter: DisplayFilter;
  language: Language;
}

type Project = {
  node: {
    frontmatter: {
      coverPhoto: any;
      projectTitle: string;
      status: string;
    };
    fields: {
      slug: string;
    };
  };
};

export const ProjectsMoodboard = ({
  previousDisplayFilter,
  displayFilter,
  language,
}: ProjectsMoodboardProps) => {
  const data = useStaticQuery(graphql`
    query ProjectsMoodboard {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "project" } } }
        sort: { order: DESC, fields: frontmatter___order }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              projectTitle
              status
              coverPhoto {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
          }
        }
      }
    }
  `);

  const [allProjects, setAllProjects] = useState<MoodboardItem[]>([]);
  const [completedProjects, setCompletedProjects] = useState<MoodboardItem[]>(
    []
  );
  const [inProgressProjects, setInProgressProjects] = useState<MoodboardItem[]>(
    []
  );
  const [projectsToRender, setProjectsToRender] = useState(<></>);
  const [navigateTo, setNavigateTo] = useState<string | undefined>(undefined);

  useEffect(() => {
    let allMoodboardItems: MoodboardItem[] = [];
    let completedMoodboardItems: MoodboardItem[] = [];
    let inProgressMoodboardItems: MoodboardItem[] = [];

    data.allMarkdownRemark.edges.forEach((project: Project) => {
      const image = getImage(
        project.node.frontmatter.coverPhoto
      ) as IGatsbyImageData;
      let moodboardItem: MoodboardItem = {
        image: (
          <GatsbyImage
            className="four-by-three"
            key={project.node.fields.slug}
            image={image}
            alt={project.node.frontmatter.projectTitle}
          />
        ),
        aspectRatio:
          (image?.height && image.width && image.height / image.width) || 1,
        link: project.node.fields.slug,
        caption: project.node.frontmatter.projectTitle,
      };
      let status = project.node.frontmatter.status as Status;

      allMoodboardItems.push(moodboardItem);
      if (status == 'Completed') completedMoodboardItems.push(moodboardItem);
      else if (status == 'In progress')
        inProgressMoodboardItems.push(moodboardItem);
    });

    setAllProjects(allMoodboardItems);
    setCompletedProjects(completedMoodboardItems);
    setInProgressProjects(inProgressMoodboardItems);
  }, [data]);

  useEffect(() => {
    const filterProjects = (filter: DisplayFilter) =>
      filter == DisplayFilter.All
        ? allProjects
        : filter == DisplayFilter.Completed
        ? completedProjects
        : inProgressProjects;

    if (previousDisplayFilter == undefined) {
      setProjectsToRender(
        <section
          id="projects-moodboard"
          className="main-section section is-medium project-active"
        >
          {useProjectMoodboard(filterProjects(displayFilter), handleOnClick)}
        </section>
      );
    } else if (previousDisplayFilter != displayFilter) {
      setProjectsToRender(
        <section
          id="projects-moodboard"
          className="main-section section is-medium project-become-inactive"
        >
          {useProjectMoodboard(
            filterProjects(previousDisplayFilter),
            handleOnClick
          )}
        </section>
      );
      setTimeout(() => {
        setProjectsToRender(
          <section
            id="projects-moodboard"
            className="main-section section is-medium project-active"
          >
            {useProjectMoodboard(filterProjects(displayFilter), handleOnClick)}
          </section>
        );
      }, 400);
    }
  }, [
    allProjects,
    completedProjects,
    inProgressProjects,
    previousDisplayFilter,
    displayFilter,
  ]);

  useLayoutEffect(() => {
    if (navigateTo) {
      navigate(navigateTo, { state: { language: language } });
    }
  }, [navigateTo]);

  const handleOnClick = (link: string) => {
    setNavigateTo(link);
  };

  return projectsToRender;
};
