import { Language, LinkLocation } from '../mlva-types';

export const LanguageEnum: { EN: Language; PT: Language } = {
  EN: 'En',
  PT: 'Pt',
};

export const getLanguageFromLocation = (location: LinkLocation): Language => {
  return location?.state?.language || LanguageEnum.EN;
};

export const useTranslation = (
  textEn: string,
  textPt: string,
  language: Language
) => {
  if (language == LanguageEnum.PT) return textPt;
  else return textEn;
};
