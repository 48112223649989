import { useState, useLayoutEffect } from 'react';
import { ScrollDetected, ScrollDirection } from '../mlva-types';

export const DirectionEnum: { Up: ScrollDirection; Down: ScrollDirection } = {
  Up: 'up',
  Down: 'down',
};

const useScrollDetected = (
  topOffset: number = 0,
  frequency: number = 10,
  ignore: boolean = false
): ScrollDetected => {
  const [scrollDetected, setScrollDetected] = useState<ScrollDetected>({
    direction: DirectionEnum.Up,
    isTop: true,
  });
  const [prevScrollY, setPrevScrollY] = useState(0);

  let timeoutId: number;

  useLayoutEffect(() => {
    const handleScroll = () => {
      clearTimeout(timeoutId);
      timeoutId = window.setTimeout(() => {
        const currentScrollY = window.scrollY;
        const scrollDistance = Math.abs(currentScrollY - prevScrollY);

        let newDirection: ScrollDirection =
          prevScrollY && currentScrollY > prevScrollY
            ? DirectionEnum.Down
            : DirectionEnum.Up;

        if (ignore) {
          newDirection = DirectionEnum.Up;
        } else if (
          newDirection == DirectionEnum.Up &&
          scrollDetected.direction == DirectionEnum.Down &&
          scrollDistance < 10
        ) {
          newDirection = DirectionEnum.Down;
        } else if (
          newDirection == DirectionEnum.Down &&
          scrollDetected.direction == DirectionEnum.Up &&
          scrollDistance < 5
        ) {
          newDirection = DirectionEnum.Up;
        } else if (
          newDirection == DirectionEnum.Down &&
          currentScrollY < topOffset
        ) {
          newDirection = DirectionEnum.Up;
        }

        const isTop = currentScrollY <= (topOffset || 0);
        setScrollDetected({ direction: newDirection, isTop });
        setPrevScrollY(currentScrollY);
      }, frequency);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollY]);

  return scrollDetected;
};

export default useScrollDetected;
