export const openHamburgerMenu = () => {
    const $navbarBurger = document.querySelector('.navbar-burger');
    const $navbarMenu = document.querySelector('.navbar-menu');

    if (!$navbarBurger?.classList.contains('is-active') && !$navbarMenu?.classList.contains('is-active')) {
        $navbarBurger?.classList.add('is-active');
        $navbarMenu?.classList.add('is-active');
    }
}

export const closeHamburgerMenu = () => {
    const $navbarBurger = document.querySelector('.navbar-burger');
    $navbarBurger?.classList.remove('is-active');

    const $navbarMenu = document.querySelector('.navbar-menu');
    $navbarMenu?.classList.remove('is-active');
};

export const toggleHamburguerMenu = () => {
    const $navbarBurger = document.querySelector('.navbar-burger');
    $navbarBurger?.classList.toggle('is-active');

    const $navbarMenu = document.querySelector('.navbar-menu');
    $navbarMenu.classList.toggle('is-active');
};
