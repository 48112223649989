import React from 'react';
import { MoodboardItem } from '../mlva-types';

export const useProjectMoodboard = (
  items: MoodboardItem[],
  handleOnClick?: (link: string) => void
) => {
  return (
    <div className="columns">
      <div className="column is-1 is-hidden-touch" />
      <div className="column is-10-desktop">
        <div className="columns is-multiline project-moodboard is-hidden-touch pt-5">
          {items?.map((item) => (
            <div className="column is-one-third">
              <div className="">
                {item.link && handleOnClick != undefined ? (
                  <a
                    onClick={() => handleOnClick(item.link)}
                    className="coverphoto-container"
                  >
                    {item.image}
                    <div className="coverphoto-caption has-text-centered">
                      {item.caption}
                    </div>
                  </a>
                ) : (
                  item.image
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="columns is-multiline project-moodboard is-hidden-desktop pt-5">
          {items?.map((item, index) => (
            <div className={`column is-half ${index % 2 ? 'pr-0' : 'pl-0'}`}>
              <div className="" key={item.link}>
                {item.link && handleOnClick ? (
                  <a
                    onClick={() => handleOnClick(item.link)}
                    className="coverphoto-container"
                  >
                    {item.image}
                    <div className="coverphoto-caption has-text-centered">
                      {item.caption}
                    </div>
                  </a>
                ) : (
                  item.image
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="column is-1 is-hidden-touch" />
      </div>
    </div>
  );
};
