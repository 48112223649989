import React, { useState } from 'react';
import './mystyles.scss';
import { ProjectsNavbar } from '../components/projects-navbar';
import { ProjectsMoodboard } from '../components/projects-moodboard';
import { Language, LinkLocation } from '../mlva-types';
import { LanguageEnum } from '../hooks/useTranslation';
import { SEO } from '../components/seo';

export enum DisplayFilter {
  All = 'ALL',
  InProgress = 'PROGRESS',
  Completed = 'COMPLETED',
}

interface WorkPageProps {
  location: LinkLocation;
}

const WorkPage = ({ location }: WorkPageProps) => {
  const [filter, setFilter] = useState<DisplayFilter>(DisplayFilter.All);
  const [previousFilter, setPreviousFilter] = useState<
    DisplayFilter | undefined
  >(undefined);
  const [language, setLanguage] = useState<Language>(
    location?.state?.language || LanguageEnum.EN
  );

  const changeLanguage = () => {
    if (language == LanguageEnum.EN) setLanguage(LanguageEnum.PT);
    else setLanguage(LanguageEnum.EN);
  };

  const changeFilter = (newFilter: DisplayFilter) => {
    setPreviousFilter(filter);
    setFilter(newFilter);
  };

  return (
    <>
      <ProjectsNavbar
        language={language}
        changeLanguage={changeLanguage}
        filter={filter}
        setFilter={changeFilter}
      />
      <ProjectsMoodboard
        previousDisplayFilter={previousFilter}
        displayFilter={filter}
        language={language}
      />
    </>
  );
};

export default WorkPage;

export const Head = () => <SEO title={'MLVA | Work'} />;
