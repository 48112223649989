import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { useTranslation } from '../hooks/useTranslation';
import { DisplayFilter } from '../pages/work';
import { toggleHamburguerMenu } from '../utils/hamburguer-menu';
import { useIsDesktop } from '../hooks/useIsDesktop';
import { Language } from '../mlva-types';
import useScrollDetected, { DirectionEnum } from '../hooks/useDetectScroll';

interface ProjectsNavbarProps {
  language: Language;
  changeLanguage: () => void;
  filter: DisplayFilter;
  setFilter: (filter: DisplayFilter) => void;
}

export const ProjectsNavbar = ({
  language,
  changeLanguage,
  filter,
  setFilter,
}: ProjectsNavbarProps) => {
  const logoPath = useStaticQuery(graphql`
    query ProjectsNavbarLogo {
      markdownRemark(frontmatter: { templateKey: { eq: "homepage-section" } }) {
        frontmatter {
          logo {
            publicURL
          }
        }
      }
    }
  `).markdownRemark.frontmatter.logo?.publicURL;

  const isDesktop = useIsDesktop();
  const scrollDetected = useScrollDetected(50, 10);

  const closeHamburguerMenu = () => {
    if (!isDesktop) toggleHamburguerMenu();
  };

  return (
    <nav
      className={`navbar is-transparent is-fixed-top is-unselectable ${
        scrollDetected.direction == DirectionEnum.Down
          ? 'navbar-hide'
          : 'navbar-display'
      }`}
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <Link
          to="/"
          className="navbar-item px-4 py-0"
          state={{ language: language }}
        >
          <img src={logoPath} alt="navigate to homepage" id="navbar-logo" />
        </Link>
        <a
          role="button"
          className="navbar-burger mr-2"
          aria-label="menu"
          aria-expanded="false"
          data-target="mlva-navbar"
          onClick={toggleHamburguerMenu}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="mlva-navbar" className="navbar-menu">
        <div className="navbar-end">
          <a
            className="navbar-item px-5"
            onClick={() => {
              setFilter(DisplayFilter.All);
              closeHamburguerMenu();
            }}
          >
            <span
              className={
                filter == DisplayFilter.All ? 'has-text-weight-medium' : ''
              }
            >
              {useTranslation('ALL', 'TODOS', language)}
            </span>
          </a>
          <a
            className="navbar-item px-5"
            onClick={() => {
              setFilter(DisplayFilter.InProgress);
              closeHamburguerMenu();
            }}
          >
            <span
              className={
                filter == DisplayFilter.InProgress
                  ? 'has-text-weight-medium'
                  : ''
              }
            >
              {useTranslation('PROGRESS', 'PROGRESSO', language)}
            </span>
          </a>
          <a
            className="navbar-item px-5"
            onClick={() => {
              setFilter(DisplayFilter.Completed);
              closeHamburguerMenu();
            }}
          >
            <span
              className={
                filter == DisplayFilter.Completed
                  ? 'has-text-weight-medium'
                  : ''
              }
            >
              {useTranslation('COMPLETED', 'CONCLUÍDOS', language)}
            </span>
          </a>
          <a
            className="navbar-item px-5"
            onClick={() => {
              changeLanguage();
            }}
          >
            <span className={language == 'Pt' ? 'has-text-weight-medium' : ''}>
              PT
            </span>
            /
            <span className={language == 'En' ? 'has-text-weight-medium' : ''}>
              EN
            </span>
          </a>
        </div>
      </div>
    </nav>
  );
};
